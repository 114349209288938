import * as React from 'react';
export interface RouteObject {
    path: string;
    exact?: boolean;
    name?: string;
    component: any;
}
const Signin = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const ResetPassword = React.lazy(() => import('./Demo/Authentication/ResetPassword/ResetPassword1'));
const ChangePassword = React.lazy(() => import('./Demo/Authentication/ChangePassword'));
const ProfileSettings = React.lazy(() => import('./Demo/Authentication/ProfileSettings'));
const PageNotFound = React.lazy(() => import('./Demo/Maintenance/PageNotFound'));

const route: RouteObject[] = [
    { path: '/', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/reset-password', exact: true, name: 'Reset Password', component: ResetPassword },
    { path: '/auth/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/auth/profile-settings', exact: true, name: 'Profile Settings', component: ProfileSettings },
    { path: '*', exact: false, name: 'PageNotFound', component: PageNotFound },
];
export default route;
