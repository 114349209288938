import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { DashboardState } from './state';
export const initialState: DashboardState = {
    conversations: [],
    users: [],
    closestTime: []
};

const dashboardReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {

    switch (action.type) {
        case actionTypes.SET_DASHBOARD:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default dashboardReducer;