// store without redux-tolkit.
import { AnyAction, applyMiddleware, CombinedState, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger, LogEntryObject } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from "redux-persist/lib/storage";
import reducer from './reducer';

function configureStore() {
  /** add middlewares */
  const middlewares: any = [];

  // middleware redux logger
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      diff: true,
      collapsed: (_getState: any, _action: any, logEntry: LogEntryObject | undefined) => !logEntry?.error,
    });
    middlewares.push(logger);
  }

  const appMiddleware = composeWithDevTools(applyMiddleware(...middlewares));

  /** config root reducer */
  const resettableAppReducer = (state: CombinedState<any>, action: AnyAction) => {
    if (action.type === 'LOG_OUT_ACTION') {
      state = {} as any;
    }
    return reducer(state, action);
  };

  /** config redux-persist */
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState'],
    blacklist: [''],
  };
  const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

    /** create redux store */
  const store = createStore(persistedReducer, appMiddleware);
  return store;
};

export const store = configureStore();
export const persistor = persistStore(store);
/*
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
*/
