import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { UserInfo, UserListState } from './state';
export const initialState: UserListState = {
    currentPage: 1,
    maxPage: 1,
    users: []
};

const userReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {
    switch (action.type) {
        case actionTypes.SET_LIST_USER:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.CHANGE_STATUS_ADVANCED_USER:
            
            const cloneListUser = JSON.parse(JSON.stringify(state.users)) as UserInfo[];
            const idx = cloneListUser.findIndex(user => user.id === action.payload.id);
            if (idx !== -1 && idx !== undefined) {
                cloneListUser[idx] = { ...cloneListUser[idx], isAdvancedUser: action.payload.status }
            }
            return {
                ...state,
                users: cloneListUser,
            };
        case actionTypes.SET_DETAIL_USER:
            return {
                ...state,
                detail: { ...state.detail, ...action.payload }
            };
        case actionTypes.SET_DETAIL_CREATED_USER:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    created: {
                        ...state.detail?.created,
                        ...action.payload,
                        data: [...(state.detail?.created?.data || []), ...action.payload.data]
                    }
                }
            };
        case actionTypes.SET_DETAIL_SCHEDULE_USER:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    schedule: {
                        ...state.detail?.schedule,
                        ...action.payload,
                        data: [...(state.detail?.schedule?.data || []), ...action.payload.data]
                    }
                }
            };
        case actionTypes.SET_DETAIL_DRAFT_USER:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    draft: {
                        ...state.detail?.draft,
                        ...action.payload,
                        data: [...(state.detail?.draft?.data || []), ...action.payload.data]
                    }
                }
            };
        case actionTypes.CLEAR_USER_STATE:
            return {
                ...state,
                currentPage: 1,
                maxPage: 1,
                users: [],
                detail: undefined
            };
        default:
            return state;
    }
};

export default userReducer;
