import { createSelectorHook } from 'react-redux';
import { combineReducers } from 'redux';
import ableReducer, { initialState as AbleState } from './ableReducer';
import authReducer, { initialState as AuthState } from './authReducer';
import conversationReducer, { initialState as ConversationState } from './conversationReducer';
import dashboardReducer, { initialState as DashboardState } from './dashboardReducer'; 
import surveyReducer, { initialState as SurveyState } from './surveyReducer'; 
import demoReducer, { initialState as DemoState } from './demoReducer';
import reportReducer, { initialState as ReportListState } from './reportReducer';
import userReducer, { initialState as UserListState } from './userReducer';

const reducer = combineReducers({
    able: ableReducer,
    demo: demoReducer,
    authState: authReducer,
    conversationState: conversationReducer,
    dashboardState: dashboardReducer,
    surveyState: surveyReducer,
    userState: userReducer,
    reportState: reportReducer,
});

export const useSelector = createSelectorHook<{
    able: typeof AbleState;
    demo: typeof DemoState;
    authState: typeof AuthState;
    conversationState: typeof ConversationState,
    dashboardState: typeof DashboardState;
    surveyState: typeof SurveyState;
    userState: typeof UserListState
    reportState: typeof ReportListState
}>();

export default reducer;
