import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import routesOnePage from '../route';
import routes from '../routes';
import { useSelector } from '../store/reducer';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';

const AdminLayout = lazy(() => import('./layout/AdminLayout'));
const App = () => {
    const location = useLocation();
    const userInfo = useSelector((state) => state.authState.userInfo);
    return (
        <>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    {!userInfo ? (
                        <Route path={routesOnePage.map((x) => x.path)}>
                            <Switch location={location} key={location.pathname}>
                                {routesOnePage.map((route, index) => {
                                    return route.component ? (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={(props: any) => <route.component {...props} />}
                                        />
                                    ) : null;
                                })}
                            </Switch>
                        </Route>
                    ) : (
                        <Route path={routes.map((x) => x.path)}>
                            <AdminLayout />
                        </Route>
                    )}
                </Suspense>
            </ScrollToTop>
            <div className="backdrop" />
        </>
    );
};
export default App;
