import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { SurveyState } from './state';
export const initialState: SurveyState = {
    conversations: [],
    users: [],
    closestTime: []
};

const surveyReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {

    switch (action.type) {
        case actionTypes.SET_SURVEY:
            return {
                ...state,
                // ...action.payload,
            };
        default:
            return state;
    }
};

export default surveyReducer;