import { Conversation, PaginationState, Report, Stump, UserInfo } from './state';
export const COLLAPSE_MENU = '@able/COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = '@able/COLLAPSE_TOGGLE';
export const CHANGE_LAYOUT = '@able/CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = '@able/CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE = '@able/LAYOUT_TYPE';
export const RESET = '@able/RESET';
export const NAV_BRAND_COLOR = '@able/NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = '@able/HEADER_BACK_COLOR';
export const RTL_LAYOUT = '@able/RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = '@able/NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = '@able/HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = '@able/BOX_LAYOUT';
export const NAV_CONTENT_LEAVE = '@able/NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = '@able/NAV_COLLAPSE_LEAVE';
export const GET_USER_INFO = '@auth/GET_USER_INFO';
export const SET_SEARCH_CONVERSATION = '@conversation/SET_SEARCH_CONVERSATION';
export const CLEAR_SEARCH_STATE = '@conversation/CLEAR_SEARCH_STATE';
export const SET_DASHBOARD = '@dashboard/SET_DASHBOARD';
export const SET_LIST_USER = '@user/SET_LIST_USER';
export const CHANGE_STATUS_ADVANCED_USER = '@user/CHANGE_STATUS_ADVANCED_USER';
export const SET_DETAIL_USER = '@user/SET_DETAIL_USER';
export const CLEAR_USER_STATE = '@user/CLEAR_USER_STATE';
export const SET_DETAIL_CONVERSATION = '@conversation/SET_DETAIL_CONVERSATION';
export const SET_DETAIL_CREATED_USER = '@user/SET_DETAIL_CREATED_USER';
export const SET_DETAIL_SCHEDULE_USER = '@user/SET_DETAIL_SCHEDULE_USER';
export const SET_DETAIL_DRAFT_USER = '@user/SET_DETAIL_DRAFT_USER';
export const SET_REPORT_PAGE = '@report/SET_REPORT_PAGE';
export const CLEAR_REPORT_STATE = '@report/CLEAR_REPORT_STATE';
export const CHANGE_STATUS_REPORT = '@report/CHANGE_STATUS_REPORT';
export const IGNORE_REPORT = '@report/IGNORE_REPORT';
export const SET_SURVEY = '@survey/SET_SURVEY';
export type ACTIONTYPE =
    | { type: typeof COLLAPSE_MENU }
    | { type: typeof COLLAPSE_TOGGLE; menu: { id: string; type: string } }
    | { type: typeof CHANGE_LAYOUT; layout: string }
    | { type: typeof CHANGE_SUB_LAYOUT; subLayout: string }
    | { type: typeof LAYOUT_TYPE; layoutType: string }
    | { type: typeof RESET }
    | { type: typeof NAV_BRAND_COLOR; payload: any }
    | { type: typeof HEADER_BACK_COLOR; headerBackColor: string }
    | { type: typeof RTL_LAYOUT }
    | { type: typeof NAV_FIXED_LAYOUT }
    | { type: typeof HEADER_FIXED_LAYOUT }
    | { type: typeof BOX_LAYOUT }
    | { type: typeof NAV_CONTENT_LEAVE }
    | { type: typeof NAV_COLLAPSE_LEAVE; menu: { id: string; type: string } }
    | { type: typeof GET_USER_INFO; payload: UserInfo }
    | { type: typeof SET_SEARCH_CONVERSATION; payload: { conversations?: Conversation[], maxPage?: number, currentPage?: number } }
    | { type: typeof SET_DASHBOARD; payload: { conversations?: Conversation[], users?: UserInfo[], closestTime: number[] } }
    | { type: typeof SET_LIST_USER; payload: { count?: number, users?: UserInfo[] } }
    | { type: typeof CHANGE_STATUS_ADVANCED_USER; payload: { id: number, status: boolean } }
    | { type: typeof SET_DETAIL_USER; payload: { user: UserInfo, created: PaginationState<Stump[]>, draft: PaginationState<Conversation[]>, schedule: PaginationState<Conversation[]> } }
    | { type: typeof SET_DETAIL_CREATED_USER; payload: PaginationState<Stump[]> }
    | { type: typeof SET_DETAIL_SCHEDULE_USER; payload: PaginationState<Conversation[]> }
    | { type: typeof SET_DETAIL_DRAFT_USER; payload: PaginationState<Conversation[]> }
    | { type: typeof CLEAR_USER_STATE }
    | { type: typeof CLEAR_SEARCH_STATE }
    | { type: typeof SET_DETAIL_CONVERSATION, payload: { conversation: Conversation } }
    | { type: typeof SET_REPORT_PAGE; payload: PaginationState<Report[]> }
    | { type: typeof CLEAR_REPORT_STATE }
    | { type: typeof CHANGE_STATUS_REPORT; payload: { stumpId: number; allowedToShow: number } }
    | { type: typeof IGNORE_REPORT; payload: { id: number; } }
    | { type: typeof SET_SURVEY; }
    ;
