import * as React from 'react';
import { RouteObject } from './route';

const Dashboard = React.lazy(() => import('./Demo/Dashboard/dashboard'));
const UsersList = React.lazy(() => import('./Demo/Users/UserList'));
const UsersProfile = React.lazy(() => import('./Demo/Users/UserProfile'));
const Conversations = React.lazy(() => import('./Demo/Conversations'));
const DetailConversation = React.lazy(() => import('./Demo/Conversations/Detail/detail-conversation'));
const SurveyComponent = React.lazy(() => import('./Demo/Survey/index'));
const FeaturePost = React.lazy(() => import('./Demo/FeaturePost'));
const AddFeaturePost = React.lazy(() => import('./Demo/FeaturePost/add'));
const ReportComponent = React.lazy(() => import('./Demo/Reports/index'));
const AdsComponent = React.lazy(() => import('./Demo/Ads/index'));
const PageNotFound = React.lazy(() => import('./Demo/Maintenance/PageNotFound'));

const routes: RouteObject[] = [
    { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/users', exact: true, name: 'User List', component: UsersList },
    { path: '/users/detail', exact: true, name: 'User Profile', component: UsersProfile },
    { path: '/conversations', exact: true, name: 'Conversations', component: Conversations },
    { path: '/conversations/:id', exact: true, name: 'Conversations', component: DetailConversation },
    { path: '/survey', exact: true, name: 'Survey Config', component: SurveyComponent },
    { path: '/feature', exact: true, name: 'Feature Post', component: FeaturePost },
    { path: '/feature/add', exact: true, name: 'Add Feature Post', component: AddFeaturePost },
    { path: '/reports', exact: true, name: 'Reports', component: ReportComponent },
    { path: '/ads', exact: true, name: 'Ads', component: AdsComponent },
    { path: '*', exact: false, name: 'PageNotFound', component: PageNotFound }
];
export default routes;
