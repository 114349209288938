import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { ConversationState } from './state';
export const initialState: ConversationState = {
    currentPage: 1,
    maxPage: 1,
    conversations: [],

};

const conversationReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {

    switch (action.type) {
        case actionTypes.SET_SEARCH_CONVERSATION:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.CLEAR_SEARCH_STATE:
            return {
                ...state,
                currentPage: 1,
                maxPage: 1,
                conversations: [],
            };
        case actionTypes.SET_DETAIL_CONVERSATION:
            return {
                ...state,
                detail: { ...state.detail, ...action.payload },
            };
        default:
            return state;
    }
};

export default conversationReducer;