import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { AuthState } from './state';
export const initialState: AuthState = {
};

const authReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {

    switch (action.type) {
        case actionTypes.GET_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;