import * as actionTypes from './actions';
import { ACTIONTYPE } from './actions';
import { Report, ReportListState } from './state';
export const initialState: ReportListState = {
    currentPage: 1,
    maxPage: 1,
    reports: []
};

const reportReducer = (state = initialState, action: ACTIONTYPE): typeof initialState => {
    switch (action.type) {
        case actionTypes.SET_REPORT_PAGE:
            return {
                ...state,
                ...action.payload,
                reports: [...action.payload.data]
            };
        case actionTypes.CHANGE_STATUS_REPORT: {
            const cloneReports: Report[] = JSON.parse(JSON.stringify(state.reports));
            const newReports = cloneReports.map((rp) => {
                if (rp.stumpId === action.payload.stumpId) {
                    return { ...rp, allowedToShow: action.payload.allowedToShow }
                }
                else {
                    return { ...rp }
                }
            })
            return {
                ...state,
                reports: newReports
            };
        }
        case actionTypes.IGNORE_REPORT: {
            const newReports = state.reports.map((rp) => {
                if (rp.id === action.payload.id) {
                    return { ...rp, status: 1 }
                }
                else {
                    return { ...rp }
                }
            });
            return {
                ...state,
                reports: newReports
            };
        }

        case actionTypes.CLEAR_REPORT_STATE:
            return {
                ...state,
                currentPage: 1,
                maxPage: 1,
                reports: []
            };
        default:
            return state;
    }
};

export default reportReducer;
